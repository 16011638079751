import { useEffect, useState } from "react";
import { useContext } from "react";
import { AdminContext } from "../../contexts/contexAdmin";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  ModalBombaTanque,
  ModalConfirmController,
  ModalCreatingPivo,
  ModalCreatingUser,
  ModalGraphicgatewayStyeld,
  ModalMedidor,
  UnidadesUserStyled,
} from "../../styles/modals";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { apiConnect } from "../../services/api";
import { toast } from "react-toastify";
import { IoCloseCircleOutline } from "react-icons/io5";
import { MdAddToPhotos, MdDeleteSweep } from "react-icons/md";
import { SquareLoading } from "../../components/loaders";
import { IoMdCloseCircle } from "react-icons/io";

import Calendar from "react-calendar";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { RiCloseCircleFill } from "react-icons/ri";
import { PiCalendarBlankThin } from "react-icons/pi";
import { format } from "date-fns";
import { HomeContext } from "../../contexts/contexHome";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

export const ModalConfirmChangesPivo = () => {
  const {
    modalPivoConfirmation,
    setModalPivoConfirmation,
    salvePivoInfo,
    handleUpdateControllerPivo,
  } = useContext(AdminContext);

  return (
    <ModalConfirmController>
      <div className="deleteContact">
        <h2>{`Tem certeza de que deseja Salvar as alterações Feitas`}</h2>
        <div>
          <button
            className="buttonYes"
            onClick={() => {
              handleUpdateControllerPivo(salvePivoInfo[0], salvePivoInfo[1]);
            }}
          >
            Sim
          </button>
          <button
            onClick={() => setModalPivoConfirmation(!modalPivoConfirmation)}
          >
            Não
          </button>
        </div>
      </div>
    </ModalConfirmController>
  );
};

export const ModalConfirmChangesTanque = () => {
  const { setModalConfimation, salveTanqueInfo } = useContext(AdminContext);

  const handleUpdateControllerPivo = async (id, dataTanque) => {
    const token = sessionStorage.getItem("connectionsToken");
    const headerApi = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      dataTanque.latitude = Number(dataTanque.latitude);
      dataTanque.longitude = Number(dataTanque.longitude);
      dataTanque.altura = Number(dataTanque.altura);
      dataTanque.altura_util = Number(dataTanque.altura_util);
      delete dataTanque.quantidade_bombas;
      delete dataTanque.unidade;
      delete dataTanque.bombas;
      delete dataTanque.niveis;
      delete dataTanque.createdAt;
      delete dataTanque.deletedAt;
      const response = await apiConnect.patch(
        `tanques/${id}/`,
        dataTanque,
        headerApi
      );
      toast.success("Alteraçoes feitas com sucesso", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      toast.warn("Falha", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } finally {
      setModalConfimation(false);
    }
  };

  return (
    <ModalConfirmController>
      <div className="deleteContact">
        <h2>{`Tem certeza de que deseja Salvar as alterações Feitas`}</h2>
        <div>
          <button
            className="buttonYes"
            onClick={() => {
              handleUpdateControllerPivo(
                salveTanqueInfo[0],
                salveTanqueInfo[1]
              );
            }}
          >
            Sim
          </button>
          <button onClick={() => setModalConfimation(false)}>Não</button>
        </div>
      </div>
    </ModalConfirmController>
  );
};

export const ModalConfirmPivoDeletion = () => {
  const {
    modalPivoDeletConfirmation,
    setModalPivoDeletConfirmation,
    salvePivoId,
    deletePivo,
  } = useContext(AdminContext);

  return (
    <ModalConfirmController>
      <div className="deleteContact">
        <h2>{`Tem certeza de que deseja Deletar esse Pivô ?`}</h2>
        <div>
          <button className="buttonYes" onClick={() => deletePivo(salvePivoId)}>
            Sim
          </button>
          <button
            onClick={() =>
              setModalPivoDeletConfirmation(!modalPivoDeletConfirmation)
            }
          >
            Não
          </button>
        </div>
      </div>
    </ModalConfirmController>
  );
};

export const CreatingPivo = () => {
  const { modalCreatingPivo, setModalCreatingPivo, handlePivotCreation } =
    useContext(AdminContext);
  const [exitModal, setExitModal] = useState(false);

  const [Unidades, setUnidades] = useState([]);
  const [loading, setloading] = useState(false);

  const ListUnidades = async () => {
    setloading(true);
    try {
      const token = sessionStorage.getItem("connectionsToken");

      const headerApi = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiConnect.get("unidade/leituratodos", headerApi);
      setUnidades(response.data);
    } catch (error) {
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    ListUnidades();
  }, []);

  const formSchema = yup.object().shape({
    name: yup.string().max(25).required("Nome do Pivô obrigatório"),
    longitude: yup.number().required("longitude Obrigatória"),
    latitude: yup.number().required("latitude Obrigatória"),
    tamanho: yup.number().required("Diâmetro Obrigatória"),
    unidade: yup.number().required("Unidade Obrigatória"),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(formSchema),
  });

  return (
    <ModalCreatingPivo>
      <form
        onSubmit={handleSubmit(handlePivotCreation)}
        onClick={(e) => {
          e.stopPropagation();
        }}
        action=""
        className={`${
          exitModal
            ? "slide-out-fwd-center-exit"
            : "slide-in-elliptic-top-fwd-enter"
        }`}
      >
        <div className="header">
          <h2>Criar Pivô</h2>
          <span
            className="closeModal"
            onClick={() => {
              setExitModal(!exitModal);
              setTimeout(() => {
                setModalCreatingPivo(!modalCreatingPivo);
                setExitModal(false);
              }, 500);
            }}
          >
            x
          </span>
        </div>
        <ul>
          <li>
            <label htmlFor="NameCreatingPivo">Nome do Pivô</label>
            <input id="NameCreatingPivo" type="text" {...register("name")} />
          </li>
          <li>
            <label htmlFor="latitudeCreatingPivo">Latitude</label>
            <input
              type="text"
              {...register("latitude", { pattern: /^-?\d*(\.\d+)?$/ })}
              id="latitudeCreatingPivo"
            />
          </li>
          <li>
            <label htmlFor="longitudeCreatingPivo">Longitude</label>
            <input
              id="longitudeCreatingPivo"
              type="text"
              {...register("longitude", { pattern: /^-?\d*(\.\d+)?$/ })}
            />
          </li>
          <li>
            <label htmlFor="tamanhoCreatingPivo">Diâmetro do Pivô</label>
            <input
              id="tamanhoCreatingPivo"
              type="number"
              {...register("tamanho")}
            />
          </li>
          <li>
            <label htmlFor="SelectUnidade">Selecione o Unidade</label>
            <select id="SelectUnidade" {...register("unidade")}>
              <option value="">Selecione...</option>
              {Unidades.map((Unidade) => {
                return <option value={Unidade.id}>{Unidade.name}</option>;
              })}
            </select>
          </li>
        </ul>
        <button type="submit">Criar Pivô</button>
      </form>
    </ModalCreatingPivo>
  );
};

export const CreatingUser = () => {
  const { setModalCreatingUser, modalCreatingUser, handleUsertCreation } =
    useContext(AdminContext);
  const [exitModal, setExitModal] = useState(false);
  const [viewPassword, setViewPassword] = useState("password");
  const [viewPassword2, setViewPassword2] = useState("password");

  const formSchema = yup.object().shape({
    email: yup.string().required("Email Obrigatório").email(),
    username: yup
      .string()
      .required("Nome do Usuário obrigatório")
      .transform((value) => value && value.toLowerCase().trim()),
    password: yup
      .string()
      .required("Senha Obrigatório")
      .matches(
        /(?=.*[A-Z])/,
        "Senha deve conter pelo menos uma letra maiúscula"
      ) // Letra maiúscula
      .matches(/(?=.*\d)/, "Senha deve conter pelo menos um número") // Número
      .matches(
        /(?=.*[!@#$%^&*()_+=[\]{};':"\\|,.<>?])/,
        "Senha deve conter pelo menos um caractere especial"
      ), // Caractere especial
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Senhas diferentes")
      .required("Senha Obrigatório"),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onError = (errors) => {
    console.log(errors.password.message);
    toast.error(errors.password.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  return (
    <ModalCreatingUser>
      <form
        onSubmit={handleSubmit(handleUsertCreation, onError)}
        onClick={(e) => {
          e.stopPropagation(); // Impede a propagação do evento de clique para os elementos pai
        }}
        action=""
        className={`${
          exitModal
            ? "slide-out-fwd-center-exit"
            : "slide-in-elliptic-top-fwd-enter"
        }`}
      >
        <div className="header">
          <h2>Criar Um Novo Usuário </h2>
          <span
            className="closeModal"
            onClick={() => {
              setExitModal(!exitModal);
              setTimeout(() => {
                setModalCreatingUser(!modalCreatingUser);
                setExitModal(false);
              }, 500);
            }}
          >
            x
          </span>
        </div>
        <ul>
          <li>
            <label htmlFor="NameCreatingPivo">Email</label>
            <input
              id="NameCreatingPivo"
              type="email"
              required={true}
              {...register("email")}
            />
            <span className="error">{errors?.email?.message}</span>
          </li>
          <li>
            <label htmlFor="latitudeCreatingPivo">Usuário</label>
            <input
              type="text"
              id="latitudeCreatingPivo"
              {...register("username")}
            />
            <span className="error">{errors?.username?.message}</span>
          </li>
          <li>
            <label htmlFor="longitudeCreatingPivo">Senha</label>
            <input
              id="longitudeCreatingPivo"
              type={viewPassword}
              required={true}
              {...register("password")}
            />
            <span className="error">{errors?.password?.message}</span>
            {viewPassword === "password" ? (
              <FaEye
                className="viewPassword"
                onClick={() => setViewPassword("text")}
              />
            ) : (
              <FaEyeSlash
                className="viewPassword"
                onClick={() => setViewPassword("password")}
              />
            )}
          </li>
          <li>
            <label htmlFor="tamanhoCreatingPivo">Confimar Senha</label>
            <input
              id="tamanhoCreatingPivo"
              type={viewPassword2}
              required={true}
              {...register("confirmPassword")}
            />
            <span className="error">{errors?.confirmPassword?.message}</span>
            {viewPassword2 === "password" ? (
              <FaEye
                className="viewPassword"
                onClick={() => setViewPassword2("text")}
              />
            ) : (
              <FaEyeSlash
                className="viewPassword"
                onClick={() => setViewPassword2("password")}
              />
            )}
          </li>
        </ul>
        <button type="submit">Criar Usuário</button>
      </form>
    </ModalCreatingUser>
  );
};

export const CreatingTanque = () => {
  const { setModalCreatTanque } = useContext(AdminContext);
  const [exitModal, setExitModal] = useState(false);

  const [Unidades, setUnidades] = useState([]);
  const [loading, setloading] = useState(false);

  const ListUnidades = async () => {
    setloading(true);
    try {
      const token = sessionStorage.getItem("connectionsToken");

      const headerApi = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiConnect.get("unidade/leituratodos", headerApi);
      setUnidades(response.data);
    } catch (error) {
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    ListUnidades();
  }, []);

  const formSchema = yup.object().shape({
    name: yup.string().max(55).required("Nome do Tanque obrigatório"),
    altura: yup.number().required("Altura Obrigatória"),
    altura_util: yup.number().required("Altura Util Obrigatória"),
    unidade: yup.number().required("Unidade Obrigatória"),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(formSchema),
  });

  const creatingTanque = async (data) => {
    const token = sessionStorage.getItem("connectionsToken");
    const headerApi = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      await apiConnect.post("tanques", data, headerApi);

      toast.success("Tanque criado com sucesso", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      toast.warn("Algo deu Errado", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <ModalCreatingPivo>
      <form
        onSubmit={handleSubmit(creatingTanque)}
        onClick={(e) => {
          e.stopPropagation();
        }}
        action=""
        className={`${
          exitModal
            ? "slide-out-fwd-center-exit"
            : "slide-in-elliptic-top-fwd-enter"
        }`}
      >
        <div className="header">
          <h2>Criar Tanque</h2>
          <span
            className="closeModal"
            onClick={() => {
              setExitModal(!exitModal);
              setTimeout(() => {
                setModalCreatTanque(false);
                setExitModal(false);
              }, 500);
            }}
          >
            x
          </span>
        </div>
        <ul>
          <li>
            <label htmlFor="NameCreatingPivo">Nome do Tanque</label>
            <input id="NameCreatingPivo" type="text" {...register("name")} />
          </li>
          <li>
            <label htmlFor="alturaCreatingTanque">Altura</label>
            <input
              type="text"
              {...register("altura", { pattern: /^-?\d*(\.\d+)?$/ })}
              id="alturaCreatingTanque"
            />
          </li>
          <li>
            <label htmlFor="altura_utilCreatingTanque">Altura Ultil</label>
            <input
              id="altura_utilCreatingTanque"
              type="text"
              {...register("altura_util", { pattern: /^-?\d*(\.\d+)?$/ })}
            />
          </li>
          <li>
            <label htmlFor="SelectUnidade">Selecione o Unidade</label>
            <select id="SelectUnidade" {...register("unidade")}>
              <option value="">Selecione...</option>
              {Unidades.map((Unidade) => {
                return <option value={Unidade.id}>{Unidade.name}</option>;
              })}
            </select>
          </li>
        </ul>
        <button type="submit">Criar Tanque</button>
      </form>
    </ModalCreatingPivo>
  );
};

export const ModalConfirmDeleteUser = () => {
  const {
    modalUserDeletConfirmation,
    setModalUserDeletConfirmation,
    salveUserId,
    deleteUser,
  } = useContext(AdminContext);

  return (
    <ModalConfirmController>
      <div className="deleteContact">
        <h2>{`Tem certeza de que deseja Deletar esse Usuário?`}</h2>
        <div>
          <button className="buttonYes" onClick={() => deleteUser(salveUserId)}>
            Sim
          </button>
          <button
            onClick={() =>
              setModalUserDeletConfirmation(!modalUserDeletConfirmation)
            }
          >
            Não
          </button>
        </div>
      </div>
    </ModalConfirmController>
  );
};

export const ModalUpdateUser = () => {
  const {
    modalEditUser,
    setModalEditUser,
    handleUserUpdate,
    salveUserId,
    usersSave,
  } = useContext(AdminContext);
  const [exitModal, setExitModal] = useState(false);

  const formSchema = yup.object().shape({
    email: yup.string().required("Email Obrigatório").email(),
    username: yup
      .string()
      .required("Nome do Usuário obrigatório")
      .transform((value) => value && value.toLowerCase().trim()),
    permission: yup.boolean().required("Permissão obrigatória"),
    is_superuser: yup.boolean().required("Admin obrigatória"),
    is_active: yup.boolean().required("Admin obrigatória"),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(formSchema),
  });

  const userInfo = usersSave.find((e) => salveUserId === e.id);
  return (
    <ModalCreatingUser>
      <form
        onSubmit={handleSubmit(handleUserUpdate)}
        onClick={(e) => {
          e.stopPropagation(); // Impede a propagação do evento de clique para os elementos pai
        }}
        action=""
        className={`${
          exitModal
            ? "slide-out-fwd-center-exit"
            : "slide-in-elliptic-top-fwd-enter"
        }`}
      >
        <div className="header">
          <h2>Editar Usuário </h2>
          <span
            className="closeModal"
            onClick={() => {
              setExitModal(!exitModal);
              setTimeout(() => {
                setModalEditUser(!modalEditUser);
                setExitModal(false);
              }, 500);
            }}
          >
            x
          </span>
        </div>
        <ul>
          <li>
            <label htmlFor="NameCreatingPivo">Email</label>
            <input
              id="NameCreatingPivo"
              type="email"
              required={true}
              defaultValue={userInfo.email}
              {...register("email")}
            />
            <span className="error">{errors?.email?.message}</span>
          </li>
          <li>
            <label htmlFor="latitudeCreatingPivo">Usuário</label>
            <input
              type="text"
              id="latitudeCreatingPivo"
              {...register("username")}
              defaultValue={userInfo.username}
            />
            <span className="error">{errors?.username?.message}</span>
          </li>
          <li className="redioInput">
            <label>Permissão</label>
            <div className="radio-container">
              <label>
                <input
                  type="radio"
                  name="permission"
                  value={true}
                  {...register("permission")}
                  defaultChecked={userInfo.permission}
                />{" "}
                Sim
              </label>
              <label>
                <input
                  type="radio"
                  name="permission"
                  value={false}
                  {...register("permission")}
                  defaultChecked={!userInfo.permission}
                />{" "}
                Não
              </label>
            </div>
            <span className="error">{errors?.permission?.message}</span>
          </li>
          <li className="redioInput">
            <label>Admin</label>
            <div className="radio-container">
              <label>
                <input
                  type="radio"
                  name="is_superuser"
                  value={true}
                  {...register("is_superuser")}
                  defaultChecked={userInfo.is_superuser}
                />{" "}
                Sim
              </label>
              <label>
                <input
                  type="radio"
                  name="is_superuser"
                  value={false}
                  {...register("is_superuser")}
                  defaultChecked={!userInfo.is_superuser}
                />{" "}
                Não
              </label>
            </div>
            <span className="error">{errors?.is_superuser?.message}</span>
          </li>
          <li className="redioInput">
            <label>Conta Ativa</label>
            <div className="radio-container">
              <label>
                <input
                  type="radio"
                  name="is_active"
                  value={true}
                  {...register("is_active")}
                  defaultChecked={userInfo.is_active}
                />{" "}
                Sim
              </label>
              <label>
                <input
                  type="radio"
                  name="is_active"
                  value={false}
                  {...register("is_active")}
                  defaultChecked={!userInfo.is_active}
                />{" "}
                Não
              </label>
            </div>
            <span className="error">{errors?.is_superuser?.message}</span>
          </li>
        </ul>
        <button type="submit">Salvar Alterações</button>
      </form>
    </ModalCreatingUser>
  );
};

export const ModalUpdatePassword = () => {
  const { modaUpdatePassword, setModalUpdatePassword, handleUserUpdate } =
    useContext(AdminContext);
  const [exitModal, setExitModal] = useState(false);
  const [viewPassword, setViewPassword] = useState("password");
  const [viewPassword2, setViewPassword2] = useState("password");

  const formSchema = yup.object().shape({
    password: yup
      .string()
      .required("Senha Obrigatório")
      .matches(
        /(?=.*[A-Z])/,
        "Senha deve conter pelo menos uma letra maiúscula"
      ) // Letra maiúscula
      .matches(/(?=.*\d)/, "Senha deve conter pelo menos um número") // Número
      .matches(
        /(?=.*[!@#$%^&*()_+=[\]{};':"\\|,.<>?])/,
        "Senha deve conter pelo menos um caractere especial"
      ), // Caractere especial
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Senhas diferentes")
      .required("Senha Obrigatório"),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onError = (errors) => {
    console.log(errors.password.message);
    toast.error(errors.password.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  return (
    <ModalCreatingUser
      onClick={() => {
        setExitModal(!exitModal);
        setTimeout(() => {
          setModalUpdatePassword(!modaUpdatePassword);
          setExitModal(false);
        }, 500);
      }}
    >
      <form
        onSubmit={handleSubmit(handleUserUpdate, onError)}
        onClick={(e) => {
          e.stopPropagation(); // Impede a propagação do evento de clique para os elementos pai
        }}
        action=""
        className={`${
          exitModal
            ? "slide-out-fwd-center-exit"
            : "slide-in-elliptic-top-fwd-enter"
        }`}
      >
        <div className="header">
          <h2>Senha</h2>
          <span
            className="closeModal"
            onClick={() => {
              setExitModal(!exitModal);
              setTimeout(() => {
                setModalUpdatePassword(!modaUpdatePassword);
                setExitModal(false);
              }, 500);
            }}
          >
            x
          </span>
        </div>
        <ul>
          <li>
            <label htmlFor="longitudeCreatingPivo">Nova Senha</label>
            <input
              id="longitudeCreatingPivo"
              type={viewPassword2}
              required={true}
              {...register("password")}
            />
            <span className="error">{errors?.password?.message}</span>
            {viewPassword === "password" ? (
              <FaEye
                className="viewPassword"
                onClick={() => setViewPassword("text")}
              />
            ) : (
              <FaEyeSlash
                className="viewPassword"
                onClick={() => setViewPassword("password")}
              />
            )}
          </li>
          <li>
            <label htmlFor="tamanhoCreatingPivo">Confirmar Senha</label>
            <input
              id="tamanhoCreatingPivo"
              type={viewPassword2}
              required={true}
              {...register("confirmPassword")}
            />
            <span className="error">{errors?.confirmPassword?.message}</span>
            {viewPassword2 === "password" ? (
              <FaEye
                className="viewPassword"
                onClick={() => setViewPassword("text")}
              />
            ) : (
              <FaEyeSlash
                className="viewPassword"
                onClick={() => setViewPassword("password")}
              />
            )}
          </li>
        </ul>
        <button type="submit">Alterar Senha</button>
      </form>
    </ModalCreatingUser>
  );
};

export const ModalGraphicGateway = ({ gatewayId }) => {
  const {
    modalGtwHistorico,
    setModalGtwHistorico,
    user,
    modalResetGTW,
    setmodalResetGTW,
  } = useContext(HomeContext);

  const [listHistorico, setListHistorico] = useState([]);
  const [calenderDate, setCalenderDate] = useState(new Date());
  const [dateDay, setDateDay] = useState(new Date().getDate());
  const [calenderOn, setCalenderOn] = useState(false);
  const [selectDate, setSelectDate] = useState("dia");
  const [selectHistorico, setselecthistorico] = useState("status");

  const ListHistoricoFuntion = async () => {
    const formatData = format(calenderDate, "dd-MM-yyyy");
    setDateDay(calenderDate.getDate());
    const token = sessionStorage.getItem("connectionsToken");

    const headerApi = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await apiConnect.get(
        `gateway/historicos/data/${gatewayId}/${formatData}/${selectDate}`,
        headerApi
      );
      setListHistorico(response.data);
    } catch (error) {
      if (error.response.status === 404) {
        setListHistorico([]);
      }
    }
  };
  const zoomOptions = {
    pan: {
      enabled: true,
      mode: "x",
    },
    zoom: {
      wheel: {
        enabled: true,
      },
      pinch: {
        enabled: true,
      },
      mode: "x",
    },
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        display: true,
        labels: {
          color: "#e7e0e0", // Cor do texto do rótulo do conjunto de dados
          font: {
            size: 16, // Tamanho da fonte do texto do eixo y
          },
        },
      },
      title: {
        display: true,
      },
      datalabels: {
        display: true,
        align: "top",
        anchor: "end",
        formatter: (value) => `${value}`,
        font: {
          size: 12,
          weight: "bold",
        },
        color: "#ffffff",
      },
      zoom: zoomOptions, // Se você estiver usando o plugin de zoom
    },
    scales: {
      x: {
        ticks: {
          color: "#f4f4f4", // Cor das labels do eixo X
        },
        grid: {
          color: "#2d2d2d7c", // Cor das linhas de grade do eixo X
          borderColor: "#d3d3d319", // Cor da linha de borda do eixo X
        },
      },
      y: {
        min: 0, // Valor mínimo do eixo Y
        max: selectHistorico === "status" ? 24 : 100, // Valor máximo do eixo Y
        ticks: {
          color: "#000000", // Cor das labels do eixo Y
          stepSize: 2, // Define o intervalo entre os ticks no eixo Y
        },
        grid: {
          color: "#242424d3", // Cor das linhas de grade do eixo Y
          borderColor: "#d3d3d31f", // Cor da linha de borda do eixo Y
        },
      },
    },
  };

  const data = {
    labels: listHistorico.map((e) => e.data_historico.split("T")[0]),
    datasets: [
      {
        label: "Horário da Atualização",
        data: listHistorico.map((e) => {
          const timeParts = e.hora_atualizacao.split(":");
          const horas = parseInt(timeParts[0], 10); // Converte horas para número inteiro
          const minutos = parseInt(timeParts[1], 10); // Converte minutos para número inteiro

          // Calcula o horário como número mantendo minutos após a vírgula
          const horarioDecimal = horas + minutos / 100;

          // Log para verificar o cálculo

          return parseFloat(horarioDecimal.toFixed(2)); // Arredonda o valor para duas casas decimais
        }),
        backgroundColor: "rgba(23, 79, 43, 0.854)",
        borderColor: "rgba(0, 0, 0, 0.952)",
        color: "white",
        borderWidth: 1,
      },
      {
        label: "Horário da conexão",
        data: listHistorico.map((e) => {
          if (
            e.data_conexao === e.data_atualizacao &&
            e.hora_atualizacao === e.hora_conexao
          ) {
            const timeParts = e.hora_conexao.split(":");
            const horas = parseInt(timeParts[0], 10); // Converte horas para número inteiro
            const minutos = parseInt(timeParts[1], 10); // Converte minutos para número inteiro

            // Calcula o horário como número mantendo minutos após a vírgula
            const horarioDecimal = horas + minutos / 100;

            return parseFloat(horarioDecimal.toFixed(2)); // Arredonda o valor para duas casas decimais
          } else {
            return 0;
          }
        }),
        backgroundColor: "rgba(32, 67, 102, 0.854)",
        borderColor: "rgba(0, 0, 0, 0.952)",
        // color: "red",
        borderWidth: 1,
      },
    ],
  };

  const dataBateria = {
    labels: listHistorico.map(
      (e) => e.data_historico.split("T")[0] + ` ${e.hora_atualizacao}`
    ),
    datasets: [
      {
        label: "Bateria Gateway",
        data: listHistorico.map((e) => {
          if (e.bateria_gateway) {
            return e.bateria_gateway;
          } else {
            return 0;
          }
        }),
        backgroundColor: "rgba(23, 79, 43, 0.854)",
        borderColor: "rgba(0, 0, 0, 0.952)",
        color: "white",
        borderWidth: 1,
      },
      {
        label: "Bateria Roteador",
        data: listHistorico.map((e) => {
          if (e.bateria_rede) {
            return e.bateria_rede;
          } else {
            return 0;
          }
        }),
        backgroundColor: "rgba(32, 67, 102, 0.854)",
        borderColor: "rgba(0, 0, 0, 0.952)",
        // color: "red",
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    ListHistoricoFuntion();
  }, []);

  useEffect(() => {
    ListHistoricoFuntion();
  }, [selectDate]);

  useEffect(() => {
    // Adiciona a classe ao abrir o modal
    if (modalGtwHistorico) {
      document.body.classList.add("modal-open");
    }

    // Remove a classe ao fechar o modal
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [modalGtwHistorico]);

  return (
    <ModalGraphicgatewayStyeld>
      <div className="container">
        <span
          className="closeModal"
          onClick={() => setModalGtwHistorico(false)}
        >
          <IoMdCloseCircle />
        </span>
        {calenderOn && (
          <div className="calendarDiv">
            <span
              className="closeModal"
              onClick={() => {
                setCalenderDate(new Date());
                setCalenderOn(false);
              }}
            >
              <RiCloseCircleFill />
            </span>
            <h3>Selecionar uma data de histórico.</h3>
            <Calendar
              className="calendar"
              onChange={setCalenderDate}
              value={calenderDate}
              maxDate={new Date()}
            />
            <div className="buutons">
              <button
                onClick={() => {
                  setCalenderOn(false);
                  ListHistoricoFuntion();
                }}
              >
                Pesquisar
              </button>
              <button
                onClick={() => {
                  setCalenderOn(false);
                  setCalenderDate(new Date());
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        )}
        <h3>Histórico Gateway</h3>
        <div className="selectorHistorico">
          <span
            onClick={() => setselecthistorico("bateria")}
            className={selectHistorico === "bateria" && "select"}
          >
            Baterias
          </span>
          <span
            onClick={() => setselecthistorico("status")}
            className={selectHistorico === "status" && "select"}
          >
            Status
          </span>
          {user.is_superuser && (
            <button
              className="ReniciarGTW"
              onClick={() => setmodalResetGTW(true)}
            >
              Reiniciar
            </button>
          )}
        </div>
        <div className="conteinerDate">
          <div className="conteinerIcon" onClick={() => setCalenderOn(true)}>
            <PiCalendarBlankThin />
            <span>{dateDay}</span>
          </div>
          <div className="ListDate">
            <select
              name="selectdate"
              id="selectdate"
              onChange={(e) => setSelectDate(e.target.value)}
            >
              <option value="dia">Dia</option>
              <option value="mes">Mes</option>
              <option value="ano">Ano</option>
            </select>
          </div>
        </div>
        {listHistorico.length === 0 && (
          <div className="not404">
            <h2>Não tem Histórico nessa data</h2>
          </div>
        )}
        <div className="grafico">
          <Bar
            options={options}
            data={selectHistorico === "status" ? data : dataBateria}
          />
        </div>
      </div>
    </ModalGraphicgatewayStyeld>
  );
};

export const MedidoresTanques = ({ tanqueInfo }) => {
  const { setModalMedidores, ListTanques, tanquesList } =
    useContext(AdminContext);

  const [modalCreatMedidor, setModalCreatMedidor] = useState(false);
  const [modalDeleteMedidor, setModalDeleteMedidor] = useState(false);
  const [idMedidor, setIdMedidor] = useState(null);
  const [medirList, setMedidorList] = useState([]);

  const creatMedidor = async () => {
    const token = sessionStorage.getItem("connectionsToken");
    const headerApi = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await apiConnect.post(
        "tanques/nivel",
        {
          tanque: tanqueInfo.id,
        },
        headerApi
      );
      ListTanques();
      toast.success("Medidor criado com  sucesso", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      toast.warn("Algo deu Errado", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const deleteMedidor = async () => {
    const token = sessionStorage.getItem("connectionsToken");
    const headerApi = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await apiConnect.delete(
        `/tanques/nivel/${idMedidor}`,
        headerApi
      );
      ListTanques();
      toast.success("Medidor deletado com sucesso", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      toast.warn("Algo deu Errado", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    let filterMedidor = tanquesList.find((e) => e.id === tanqueInfo.id);
    setMedidorList(filterMedidor.niveis);
  }, [tanquesList, tanqueInfo]);

  return (
    <ModalMedidor>
      {modalCreatMedidor && (
        <div className="confimation">
          <div className="creatMedidor">
            <h2>{`Tem certeza que deseja criar novo Medidor`}</h2>
            <div>
              <button
                className="buttonYes"
                onClick={() => {
                  creatMedidor();
                  setModalCreatMedidor(false);
                }}
              >
                Sim
              </button>
              <button onClick={() => setModalCreatMedidor(false)}>Não</button>
            </div>
          </div>
        </div>
      )}
      {modalDeleteMedidor && (
        <div className="confimation">
          <div className="creatMedidor">
            <h2>{`Tem certeza de que deseja Deletar o Medidor`}</h2>
            <div>
              <button
                className="buttonYes"
                onClick={() => {
                  deleteMedidor();
                  setModalDeleteMedidor(false);
                }}
              >
                Sim
              </button>
              <button onClick={() => setModalDeleteMedidor(false)}>Não</button>
            </div>
          </div>
        </div>
      )}
      <div className="conteiner">
        <span className="closeModal" onClick={() => setModalMedidores(false)}>
          <IoCloseCircleOutline />
        </span>
        <h3>{tanqueInfo.name}</h3>
        <span className="creatNivel" onClick={() => setModalCreatMedidor(true)}>
          <MdAddToPhotos className="icon" /> Criar novo Medidor
        </span>
        <ul>
          {medirList.map((medidor) => {
            return (
              <li>
                <span className="headerLi">Medidor</span>
                <span>Id : {medidor.id}</span>
                <span>Nível : {medidor.nivel}</span>
                <span>Vazao : {medidor.vazao}</span>
                <button
                  onClick={() => {
                    setIdMedidor(medidor.id);
                    setModalDeleteMedidor(true);
                  }}
                >
                  Deletar
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </ModalMedidor>
  );
};

export const BombasTanques = ({ tanqueInfo }) => {
  const { setModalBombaTaque, tanquesList, ListTanques } =
    useContext(AdminContext);

  const [bombaList, setBombaList] = useState([]);
  const [editedBomba, SetEditedBomba] = useState({});
  const [saveFuntionUpdateBomba, setSaveFuntionUpdateBomba] = useState([]);
  const [modalConfimationCreatBomba, SetModalConfimationCreatBomba] =
    useState(false);
  const [modalConfirmationChanges, setModalConfirmationChanges] =
    useState(false);
  const [modalDeleteBomba, setModalDeleteBomba] = useState(false);
  const [idDeleteBomba, setIdDeleteBomba] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e, bombaId, type) => {
    SetEditedBomba((prevEditedTanque) => ({
      ...prevEditedTanque,
      [bombaId]: {
        ...prevEditedTanque[bombaId],
        [type]: e,
      },
    }));
  };

  const updateBomba = async (idBomba, data) => {
    setLoading(true);
    const token = sessionStorage.getItem("connectionsToken");
    const headerApi = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      data.rotacaoNominal = Number(data.rotacaoNominal);
      data.rotacaoMaxima = Number(data.rotacaoMaxima);
      data.vazaoMax = Number(data.vazaoMax);
      data.vazaoMin = Number(data.vazaoMin);

      const response = await apiConnect.patch(
        `/tanques/bomba/${idBomba}`,
        data,
        headerApi
      );
      ListTanques();
      toast.success("Alterações feitas com sucesso", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      toast.warn("Algo deu Errado", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setModalConfirmationChanges(false);
      setLoading(false);
    }
  };

  const creatBomba = async () => {
    setLoading(true);
    const token = sessionStorage.getItem("connectionsToken");
    const headerApi = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await apiConnect.post(
        "tanques/bomba",
        { tanque: tanqueInfo.id },
        headerApi
      );
      await apiConnect.post(
        "tanques/bomba/controle",
        { bomba: response.data.id },
        headerApi
      );
      await apiConnect.post(
        "tanques/bomba/dados",
        { bomba: response.data.id },
        headerApi
      );
      ListTanques();
      toast.success("Bomba criada com sucesso", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      toast.warn("Algo deu Errado", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      SetModalConfimationCreatBomba(false);
      setLoading(false);
    }
  };

  const deleteBomba = async () => {
    setLoading(true);
    const token = sessionStorage.getItem("connectionsToken");
    const headerApi = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      await apiConnect.delete(`/tanques/bomba/${idDeleteBomba}`, headerApi);

      ListTanques();
      toast.success("Bomba deletada com sucesso", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      toast.warn("Algo deu Errado", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let filterMedidor = tanquesList.find((e) => e.id === tanqueInfo.id);
    setBombaList(filterMedidor.bombas);
  }, [tanquesList, tanqueInfo]);

  useEffect(() => {
    const initialEditedPivos = {};
    bombaList.forEach((tanque) => {
      initialEditedPivos[tanque.id] = { ...tanque };
    });
    SetEditedBomba(initialEditedPivos);
  }, [bombaList]);

  return (
    <ModalBombaTanque>
      {loading && <SquareLoading />}
      {modalConfirmationChanges && (
        <div className="confimation">
          <div className="ConfirmationChanges">
            <h2>{`Tem certeza de que deseja Salvar as Alteração`}</h2>
            <div>
              <button
                className="buttonYes"
                onClick={() => {
                  updateBomba(
                    saveFuntionUpdateBomba[0],
                    saveFuntionUpdateBomba[1]
                  );
                }}
              >
                Sim
              </button>
              <button onClick={() => setModalConfirmationChanges(false)}>
                Não
              </button>
            </div>
          </div>
        </div>
      )}
      {modalConfimationCreatBomba && (
        <div className="confimation">
          <div className="ConfirmationChanges">
            <h2>{`Tem certeza que deseja criar nova Bomba`}</h2>
            <div>
              <button
                className="buttonYes"
                onClick={() => {
                  creatBomba();
                }}
              >
                Sim
              </button>
              <button onClick={() => SetModalConfimationCreatBomba(false)}>
                Não
              </button>
            </div>
          </div>
        </div>
      )}
      {modalDeleteBomba && (
        <div className="confimation">
          <div className="ConfirmationChanges">
            <h2>{`Tem certeza que deseja Deletar a Bomba`}</h2>
            <div>
              <button
                className="buttonYes"
                onClick={() => {
                  deleteBomba();
                  setModalDeleteBomba(false);
                }}
              >
                Sim
              </button>
              <button onClick={() => setModalDeleteBomba(false)}>Não</button>
            </div>
          </div>
        </div>
      )}

      <div className="conteiner">
        <span className="closeModal">
          <IoCloseCircleOutline onClick={() => setModalBombaTaque(false)} />
        </span>
        <h3>{tanqueInfo.name}</h3>
        <span
          className="creatNivel"
          onClick={() => SetModalConfimationCreatBomba(true)}
        >
          <MdAddToPhotos className="icon" /> Criar nova Bomba
        </span>
        <ul>
          {bombaList.map((bomba) => {
            const cancelChanges = () => {
              SetEditedBomba((prevEditedTanques) => ({
                ...prevEditedTanques,
                [bomba.id]: {
                  ...prevEditedTanques[bomba.id],
                  potencia: bomba.potencia,
                  rotacaoMaxima: bomba.rotacaoMaxima,
                  rotacaoNominal: bomba.rotacaoNominal,
                  vazaoMax: bomba.vazaoMax,
                  vazaoMin: bomba.vazaoMin,
                },
              }));
            };
            const checkChangesTanque =
              JSON.stringify(bomba) === JSON.stringify(editedBomba[bomba.id]);
            return (
              <li>
                <MdDeleteSweep
                  className="deleteBomba"
                  onClick={() => {
                    setIdDeleteBomba(bomba.id);
                    setModalDeleteBomba(true);
                  }}
                />
                <span className="IdBomba">Bomba id {bomba.id}</span>
                <div className="infosPivo">
                  <label htmlFor={`${bomba.id}potencia`}>Potencia</label>
                  <input
                    type="number"
                    id={`${bomba.id}potencia`}
                    value={editedBomba[bomba.id]?.potencia}
                    onChange={(e) =>
                      handleInputChange(e.target.value, bomba.id, "potencia")
                    }
                  />
                </div>
                <div className="infosPivo">
                  <label htmlFor={`${bomba.id}rotacaoNominal`}>
                    Rotação Nominal
                  </label>
                  <input
                    type="number"
                    id={`${bomba.id}rotacaoNominal`}
                    value={editedBomba[bomba.id]?.rotacaoNominal}
                    onChange={(e) =>
                      handleInputChange(
                        e.target.value,
                        bomba.id,
                        "rotacaoNominal"
                      )
                    }
                  />
                </div>
                <div className="infosPivo">
                  <label htmlFor={`${bomba.id}rotacaoMaxima`}>
                    Rotação Maxima
                  </label>
                  <input
                    type="number"
                    id={`${bomba.id}rotacaoMaxima`}
                    value={editedBomba[bomba.id]?.rotacaoMaxima}
                    onChange={(e) =>
                      handleInputChange(
                        e.target.value,
                        bomba.id,
                        "rotacaoMaxima"
                      )
                    }
                  />
                </div>
                <div className="infosPivo">
                  <label htmlFor={`${bomba.id}vazaoMax`}>Vazão Maxima</label>
                  <input
                    type="number"
                    id={`${bomba.id}vazaoMax`}
                    value={editedBomba[bomba.id]?.vazaoMax}
                    onChange={(e) =>
                      handleInputChange(e.target.value, bomba.id, "vazaoMax")
                    }
                  />
                </div>
                <div className="infosPivo">
                  <label htmlFor={`${bomba.id}vazaoMin`}>Vazão Mínima</label>
                  <input
                    type="number"
                    id={`${bomba.id}vazaoMin`}
                    value={editedBomba[bomba.id]?.vazaoMin}
                    onChange={(e) =>
                      handleInputChange(e.target.value, bomba.id, "vazaoMin")
                    }
                  />
                </div>
                <div className="buttons">
                  <button
                    className={checkChangesTanque ? "off" : ""}
                    disabled={checkChangesTanque}
                    onClick={() => {
                      setModalConfirmationChanges(true);
                      // setSalveTanqueInfo([tanque.id, editedTanques[tanque.id]]);
                      setSaveFuntionUpdateBomba([
                        bomba.id,
                        editedBomba[bomba.id],
                      ]);
                    }}
                  >
                    Salvar
                  </button>
                  <button
                    className={checkChangesTanque ? "off" : ""}
                    disabled={checkChangesTanque}
                    onClick={() => cancelChanges()}
                  >
                    Cancelar
                  </button>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </ModalBombaTanque>
  );
};

export const UserUnidades = () => {
  const { salveUserId, usersSave, setUnidadesuser } = useContext(AdminContext);

  const [Unidades, setUnidades] = useState([]);
  const [loading, setloading] = useState(false);
  const [saveUnidadeUser, setSaveUnidadeUser] = useState([]);

  const userInfo = usersSave.find((e) => salveUserId === e.id);

  const ListUnidades = async () => {
    setloading(true);
    try {
      const token = sessionStorage.getItem("connectionsToken");

      const headerApi = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiConnect.get("unidade/leituratodos", headerApi);
      setUnidades(response.data);
    } catch (error) {
    } finally {
      setTimeout(() => {
        setloading(false);
      }, 300);
    }
  };

  useEffect(() => {
    ListUnidades();
    if (userInfo.unidades != null) {
      setSaveUnidadeUser(userInfo.unidades);
    }
  }, []);

  const toggleUnidade = (numero) => {
    setSaveUnidadeUser((prevUnidades) => {
      // Verifica se o número já está no array
      if (prevUnidades.includes(numero)) {
        // Se estiver, remove o número
        return prevUnidades.filter((unidade) => unidade !== numero);
      }
      // Se não estiver, adiciona o número
      else return [...prevUnidades, numero];
    });
  };

  const EditUserUnidade = async () => {
    setloading(true);
    const token = sessionStorage.getItem("connectionsToken");

    const headerApi = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const dataAttUnidade = {
      unidades: saveUnidadeUser,
    };
    try {
      const responseApi = await apiConnect.patch(
        `users/${salveUserId}/`,
        dataAttUnidade,
        headerApi
      );

      toast.success("Alterações Salvas com sucesso", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      toast.warn("🤐Algo de Errado", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setloading(false);
      setUnidadesuser(false);
    }
  };

  return (
    <UnidadesUserStyled>
      {loading && <SquareLoading />}
      <div className="buttonClose">
        <span className="closeModal" onClick={() => setUnidadesuser(false)}>
          x
        </span>
      </div>
      <ul>
        {Unidades.map((unidade) => {
          let ListunidadeUser = 0;

          if (userInfo.unidades !== null) {
            ListunidadeUser = userInfo.unidades.filter((e) => e === unidade.id);
          }
          return (
            <li key={unidade.id}>
              <div className="checkbox-wrapper-24">
                <input
                  type="checkbox"
                  id={`check-24${unidade.id}`}
                  name="check"
                  defaultChecked={ListunidadeUser.length > 0}
                  onChange={() => {
                    toggleUnidade(unidade.id);
                  }}
                />

                <label for={`check-24${unidade.id}`}>
                  <span></span>
                  {unidade.name}
                </label>
              </div>
            </li>
          );
        })}
      </ul>
      <div className="buttonSalve">
        <button onClick={() => EditUserUnidade()}>Salvar</button>
      </div>
    </UnidadesUserStyled>
  );
};

export const ModalCreatClima = () => {
  const [exitModal, setExitModal] = useState(false);
  const [Unidades, setUnidades] = useState([]);
  const [loading, setloading] = useState(false);

  const ListUnidades = async () => {
    setloading(true);
    try {
      const token = sessionStorage.getItem("connectionsToken");

      const headerApi = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiConnect.get("unidade/leituratodos", headerApi);
      setUnidades(response.data);
    } catch (error) {
    } finally {
      setloading(false);
    }
  };

  const formSchema = yup.object().shape({
    nome: yup.string().max(25).required("Nome obrigatório"),
    cidade: yup.string().max(50).required("Cidade obrigatório"),
    uf: yup.string().max(3).required("uf obrigatório"),
    longitude: yup.number().required("longitude Obrigatória"),
    latitude: yup.number().required("latitude Obrigatória"),
    unidade: yup.number().required("Unidade Obrigatória"),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(formSchema),
  });

  const handleMeteologiaCreation = (data) => {
    console.log(data, "----------");
  };

  useEffect(() => {
    ListUnidades();
  }, []);

  return (
    <ModalCreatingPivo>
      <form
        onSubmit={handleSubmit(handleMeteologiaCreation)}
        onClick={(e) => {
          e.stopPropagation();
        }}
        action=""
        className={`${
          exitModal
            ? "slide-out-fwd-center-exit"
            : "slide-in-elliptic-top-fwd-enter"
        }`}
      >
        <div className="header">
          <h2>Estação Meteorológica</h2>
          <span
            className="closeModal"
            // onClick={() => {
            //   setExitModal(!exitModal);
            //   setTimeout(() => {
            //     setModalCreatingPivo(!modalCreatingPivo);
            //     setExitModal(false);
            //   }, 500);
            // }}
          >
            x
          </span>
        </div>
        <ul>
          <li>
            <label htmlFor="NameCreatingMeteologia">Nome da estação</label>
            <input
              id="NameCreatingMeteologia"
              type="text"
              {...register("nome")}
            />
          </li>
          <li>
            <label htmlFor="cityCreatingMeteologia">Cidade</label>
            <input
              id="cityCreatingMeteologia"
              type="text"
              {...register("cidade")}
            />
          </li>
          <li>
            <label htmlFor="ufCreatingMeteologia">UF</label>
            <input id="ufCreatingMeteologia" type="text" {...register("uf")} />
          </li>
          <li>
            <label htmlFor="latitudeCreatingPivo">Latitude</label>
            <input
              type="text"
              {...register("latitude", { pattern: /^-?\d*(\.\d+)?$/ })}
              id="latitudeCreatingPivo"
            />
          </li>
          <li>
            <label htmlFor="longitudeCreatingPivo">Longitude</label>
            <input
              id="longitudeCreatingPivo"
              type="text"
              {...register("longitude", { pattern: /^-?\d*(\.\d+)?$/ })}
            />
          </li>
          <li>
            <label htmlFor="SelectUnidade">Selecione o Unidade</label>
            <select
              id="SelectUnidade"
              // {...register("unidade")}
            >
              <option value="">Selecione...</option>
              {Unidades.map((Unidade) => {
                return <option value={Unidade.id}>{Unidade.name}</option>;
              })}
            </select>
          </li>
        </ul>
        <button type="submit">Criar Pivô</button>
      </form>
    </ModalCreatingPivo>
  );
};

export const ModalConfirmChangesResetGTW = ({ gatewayId }) => {
  const { setmodalResetGTW } = useContext(HomeContext);

  const [loading, setloading] = useState(false);

  const postRestartGTW = async () => {
    setloading(true);
    try {
      const token = sessionStorage.getItem("connectionsToken");

      const headerApi = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      console.log(token);
      const response = await apiConnect.post(
        `gateway/reset-gateway/${gatewayId}`,
        {},
        headerApi
      );

      console.log(response);

      toast.success("Comando para gateway Reiniciar enviando", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      toast.warn("🤐Algo de Errado", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setmodalResetGTW(false);
      setloading(false);
    }
  };

  return (
    <ModalConfirmController>
      {loading && <SquareLoading />}
      <div className="deleteContact">
        <h2>{`Tem certeza de que deseja Reiniciar o Gateway?`}</h2>
        <div>
          <button className="buttonYes" onClick={() => postRestartGTW()}>
            Sim
          </button>
          <button onClick={() => setmodalResetGTW(false)}>Não</button>
        </div>
      </div>
    </ModalConfirmController>
  );
};
